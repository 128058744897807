



















































import { Component, Vue } from 'vue-property-decorator';
import VButton from '@/components/VButton.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';

@Component({
  components: { VButton },
})
export default class Prices extends Vue {

  private priceCards = [
    {
      header1: '55',
      header2: 'Basic',
      header3: '1 gebruiker',
      body: [
        'Onbeperkt berekeningen maken',
        'Wel samenvatting: geen afdruk mogelijkheid (pdf)',
        'Geen logo uploaden',
        'Per extra gebruiker € 9,- p/m',
      ],
    },
    {
      header1: '89',
      header2: 'Plus',
      header3: '+2 extra gebruikers',
      body: [
        'Onbeperkt berekeningen maken',
        'Eigen logo uploaden',
        'Onbeperkt PDF\'s',
        'Onbeperkt uitzendbevestigingen',
        'Per extra gebruiker € 9,- p/m',
      ],
    },
    {
      header1: '139',
      header2: 'Premium',
      header3: '+9 extra gebruikers',
      body: [
        'Onbeperkt berekeningen maken',
        'Eigen logo uploaden',
        'Onbeperkt PDF\'s',
        'Onbeperkt uitzendbevestigingen',
        'Per extra gebruiker € 9,- p/m',
      ],
    },
  ];
}
